var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bTN7YeCwU47pI-Xk9KhI0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.

import * as Sentry from '@sentry/nextjs';
import { SENTRY } from 'Data/constants';

const getSentryEnv = () => {
  return process.env.NEXT_PUBLIC_SENTRY_FE_ENV ?? SENTRY.LOCAL_ENV;
};

const getTransactionName = () => {
  if (window.location.pathname === '/') {
    return SENTRY.HOME;
  }
  return SENTRY.CATEGORY;
};

export const getSentrySampleRate = () => {
  return process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE
    ? parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE)
    : SENTRY.SENTRY_DEFAULT_SAMPLE_RATE;
};

Sentry.init({
  dsn: SENTRY.DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  //Sentry recommends setting this value between around 0.3 once you can validate prod is working
  tracesSampleRate: getSentrySampleRate(),
  environment: getSentryEnv(),
  integrations: [
    new Sentry.BrowserTracing({
      beforeNavigate: (context) => {
        return {
          ...context,
          // This name is hardcoded for now. we will revisit this once the home page is released.
          name: getTransactionName(),
        };
      },
    }),
  ],
});
